/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Store } from "../Store";

import AdminSideBarItems from "./DashboardItems/AdminSideBarItems";
import TeacherSideBarItems from "./DashboardItems/TeacherSideBarItems";
import StudentSideBarItems from "./DashboardItems/StudentSideBarItems";
import { useLogoutMutation } from "../services/auth";

const SideBar = ({ children }) => {
  const {
    state: { userInfo },
    dispatch,
  } = useContext(Store);
  const logoutMutation = useLogoutMutation();

  const signoutHandler = async () => {
    try {
      await logoutMutation.mutateAsync(userInfo.token);
      dispatch({ type: "USER_SIGNOUT" });
      localStorage.removeItem("userInfo");
      window.location.href = "http://sofis.com.ng/";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-dark sidebar sidebar-dark accordion"
  );

  const changeStyle = () => {
    if (
      style === "navbar-nav bg-gradient-dark sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-dark sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-dark sidebar sidebar-dark accordion");
    }
  };
  const changeStyle1 = () => {
    if (
      style === "navbar-nav bg-gradient-dark sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-dark sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-dark sidebar sidebar-dark accordion");
    }
  };

  return (
    <body id="page-top">
      {/*  <!-- Page Wrapper --> */}
      <div id="wrapper">
        {/*  <!-- Sidebar --> */}

        {userInfo ? (
          userInfo.role === "admin" ? (
            <AdminSideBarItems style={style} changeStyle={changeStyle} />
          ) : userInfo.role === "teacher" ? (
            <TeacherSideBarItems style={style} changeStyle={changeStyle} />
          ) : userInfo.role === "student" ? (
            <StudentSideBarItems style={style} changeStyle={changeStyle} />
          ) : null
        ) : (
          (() => {
            window.location.href = "http://sofis.com.ng/";
            return null;
          })()
        )}

        {/*  <!-- End of Sidebar --> */}

        {/*  <!-- Content Wrapper --> */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*  <!-- Main Content --> */}
          <div id="content">
            {/*  <!-- Topbar --> */}
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
              {/*  <!-- Sidebar Toggle (Topbar) --> */}
              <button
                id="sidebarToggleTop"
                className="btn btn-link d-md-none rounded-circle mr-3"
                onClick={changeStyle1}
              >
                <i className="fa fa-bars"></i>
              </button>

              {/*  <!-- Topbar Search --> */}
              <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>

              {/*  <!-- Topbar Navbar --> */}
              <ul className="navbar-nav ml-auto">
                {/*  <!-- Nav Item - Search Dropdown (Visible Only XS) --> */}
                <li className="nav-item dropdown no-arrow d-sm-none">
                  <div
                    className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                    aria-labelledby="searchDropdown"
                  >
                    <form className="form-inline mr-auto w-100 navbar-search">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control bg-light border-0 small"
                          placeholder="Search for..."
                          aria-label="Search"
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="button">
                            <i className="fas fa-search fa-sm"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>

                <div className="topbar-divider d-none d-sm-block"></div>

                {/* <!-- Nav Item - User Information --> */}
                <li className="nav-item dropdown no-arrow">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                      {/* {userInfo?.user ? userInfo?.user : ""} */}
                    </span>
                    <img
                      className="img-profile rounded-circle"
                      src="img/undraw_profile.svg"
                      alt=""
                    />
                  </NavLink>
                  {/*  <!-- Dropdown - User Information --> */}
                  <div
                    className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                  >
                    <NavLink className="dropdown-item" href="#">
                      <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                      Profile
                    </NavLink>
                    <NavLink className="dropdown-item" href="#">
                      <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                      Settings
                    </NavLink>
                    <NavLink className="dropdown-item" href="#">
                      <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                      Activity Log
                    </NavLink>
                    <div className="dropdown-divider"></div>
                    <button
                      className="dropdown-item"
                      onClick={signoutHandler}
                      data-toggle="modal"
                      data-target="#logoutModal"
                    >
                      <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                      Logout
                    </button>
                  </div>
                </li>
              </ul>
            </nav>
            {/*  <!-- End of Topbar --> */}

            {/* <!-- Begin Page Content --> */}
            {children}
            {/*   <!-- /.container-fluid --> */}
          </div>
          {/*   <!-- End of Main Content -->

                                        <!-- Footer --> */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>
                  Copyright &copy; Sound Foundation Internation School
                </span>
              </div>
            </div>
          </footer>
          {/* <!-- End of Footer --> */}
        </div>
        {/*  <!-- End of Content Wrapper --> */}
      </div>
      {/*  <!-- End of Page Wrapper -->

                                <!-- Scroll to Top Button--> */}
      <NavLink className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </NavLink>

      {/*  <!-- Logout Modal--> */}
      <div
        className="modal fade"
        id="logoutModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to Leave?
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Select "Logout" below if you are ready to end your current
              session.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <NavLink className="btn btn-primary" to="/login">
                Logout
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default SideBar;
