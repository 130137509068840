/* import logo from './logo.svg'; */
import "./App.css";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { StoreProvider } from "./Store";
import Classes from "./pages/Classes";
import Teachers from "./pages/Teacher";
import Students from "./pages/Students";
import Admin from "./pages/Admin";
import Subjects from "./pages/Subjects";
import ClassAndSubject from "./pages/Teachers/ClassAndSubject";
import TeacherStudent from "./pages/Teachers/TeacherStudent";
import AssignSubjectToClass from "./pages/AssignSubjectToClass";
import AssignTeacherToClass from "./pages/AssignTeacherToClass";
import ClassTimetable from "./pages/ClassTimeTable";
import ExamScheduleForm from "./pages/ExamScheduleForm";
import Exam from "./pages/Exam";
import MarkRegister from "./pages/Teachers/MarkRegister";
import Result from "./pages/Result";
import CummulativeMarkExam from "./pages/Teachers/CummulativeMarkExam";
import CustomizeHomepage from "./pages/CustomizeHomePage";
import Postpage from "./pages/Post";
import Parents from "./pages/Parents";
import UserProfile from "./pages/UserProfile";
import UpdateAccount from "./pages/UpdateAccount";
import Settings from "./pages/Settings";
import AttendancePage from "./pages/Attendance";
import AttendanceReport from "./pages/AttendanceReport";
import ExtranceExam from "./pages/EntranceExam";
import ExamResult from "./pages/ExamResult";
import AdminProfile from "./pages/AdminProfile";
import StudentProfile from "./pages/StudentProfile";
import TeacherProfile from "./pages/TeachersProfle";
import Profile from "./pages/Teachers/Profile";
import PrintResult from "./pages/Teachers/PrintResult";
import ClassAttendance from "./pages/Teachers/ClassAttendance";
import AttendanceList from "./pages/Teachers/AttendanceList";
import PsychomotorPage from "./pages/PsychomotorPage";
import StudentLogin from "./pages/StudentLogin";
import StudentResult from "./pages/StudentResult";
import ScratchCards from "./pages/ScratchCard";

function App() {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <Router>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
          },
        }}
      />
      <StoreProvider>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            {/* admin items */}
            <Route path="/classes" element={<Classes />} />
            <Route path="/teachers" element={<Teachers />} />
            <Route path="/students" element={<Students />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/subjects" element={<Subjects />} />
            <Route path="/examschedule" element={<ExamScheduleForm />} />
            <Route path="/exam" element={<Exam />} />
            <Route path="/examresult" element={<ExamResult />} />
            <Route path="/scratchCard" element={<ScratchCards />} />
            <Route
              path="/assignsubjectoclass"
              element={<AssignSubjectToClass />}
            />
            <Route
              path="/assignteachertoclass"
              element={<AssignTeacherToClass />}
            />
            {/* student */}
            <Route path="/auth" element={<StudentLogin />} />
            <Route path="/makepost" element={<Postpage />} />
            <Route path="/customizeHomePage" element={<CustomizeHomepage />} />
            <Route path="/classtimetable" element={<ClassTimetable />} />
            <Route path="/parents" element={<Parents />} />
            <Route path="/view-profile" element={<UserProfile />} />
            <Route path="/update-profile" element={<UpdateAccount />} />
            <Route path="/setting" element={<Settings />} />
            <Route path="/attendance" element={<AttendancePage />} />
            <Route path="/attendanceReport" element={<AttendanceReport />} />
            <Route path="/entraceexam" element={<ExtranceExam />} />
            <Route path="/psychomotor/:id" element={<PsychomotorPage />} />
            <Route path="/student-result" element={<StudentResult />} />
            <Route path="/adminprofile/:id" element={<AdminProfile />} />
            <Route path="/studentprofile/:id" element={<StudentProfile />} />
            <Route path="/teacherprofile/:id" element={<TeacherProfile />} />

            {/* teachers items */}
            <Route path="/classandsubject" element={<ClassAndSubject />} />
            <Route path="/teacherstudents" element={<TeacherStudent />} />
            <Route path="/markregister" element={<MarkRegister />} />
            <Route path="/printresult" element={<Result />} />
            <Route path="/cummulativeexam" element={<CummulativeMarkExam />} />
            <Route path="/teacher-profile" element={<Profile />} />
            <Route path="/teachersresult" element={<PrintResult />} />
            <Route path="/classattendance" element={<ClassAttendance />} />
            <Route path="/attendancelist" element={<AttendanceList />} />
          </Routes>
        </QueryClientProvider>
      </StoreProvider>
    </Router>
  );
}

export default App;
