/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all results
export const useGetResultsQuery = () => {
  return useQuery({
    queryKey: ["results"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/result/results");
      return data;
    },
  });
};
export const useGetResultsByStudentQuery = (student_id) => {
  return useQuery({
    queryKey: ["results", student_id],
    queryFn: async () => {
      if (!student_id) return [];

      const { data } = await apiClient.get(
        `/api/result/results/single/${student_id}`
      );
      return data;
    },
    enabled: !!student_id, // Prevent execution if student_id is not provided
  });
};
// Hook for fetching a single result by student, term, and class
export const useGetResultQuery = ({
  studentId,
  selectedClass,
  selectedTerm,
}) => {
  return useQuery({
    queryKey: ["result", studentId, selectedClass, selectedTerm],
    queryFn: async () => {
      const { data } = await apiClient.get(
        `api/result/result?studentId=${studentId}&selectedClass=${selectedClass}&term=${selectedTerm}`
      );
      return data;
    },
    enabled: !!studentId && !!selectedClass && !!selectedTerm,
  });
};

export const useGetStudentQuery = ({ studentId }) => {
  return useQuery({
    queryKey: ["student", studentId],
    queryFn: async () => {
      if (!studentId) {
        throw new Error("Student ID is required");
      }
      const { data } = await apiClient.get(`/api/result/student/${studentId}`);
      return data;
    },
    enabled: !!studentId,
  });
};

export const useGetTermQuery = ({ id }) => {
  return useQuery({
    queryKey: ["term", id],
    queryFn: async () => {
      if (!id) {
        throw new Error("term ID is required");
      }
      const { data } = await apiClient.get(`/api/result/term/${id}`);
      return data;
    },
    enabled: !!id,
  });
};

export const useGetClassQuery = ({ class_name }) => {
  return useQuery({
    queryKey: ["class", class_name],
    queryFn: async () => {
      if (!class_name) {
        throw new Error("term class name is required");
      }
      const { data } = await apiClient.get(`/api/result/class/${class_name}`);
      return data;
    },
    enabled: !!class_name,
  });
};

// Hook for updating a result
export const useUpdateResultMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (result) =>
      (await apiClient.post("api/result/add-or-update-result", result)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["results"]);
    },
  });
};
