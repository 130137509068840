import React, { useContext, useEffect, useState } from "react";
import { useAddTeacherMutation } from "../hooks/teacherHook";
import toast from "react-hot-toast";
import { Store } from "../Store";
import { useNavigate } from "react-router-dom";

const AddTeacherModal = ({ show, handleClose, editingTeacher }) => {
  const {
    state: { userInfo },
  } = useContext(Store);
  const navigate = useNavigate();
  const { mutate: addTeacher } = useAddTeacherMutation();
  const [formData, setFormData] = useState({
    userid: "",
    username: "",
    admission_date: "",
    gender: "",
    date_of_birth: "",
    religion: "",
    qualification: "",
    address: "",
    marital_status: "",
    mobile_number: "",
    profile_pic: "",
    status: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();

      addTeacher(formData, editingTeacher?.userid);
      navigate("/dashboard");
      handleClose();
      toast.success("added successfully");
    } catch (error) {
      toast.error(error);
    }
  };

  const resetFormData = () => {
    setFormData({
      userid: "",
      username: "",
      admission_date: "",
      gender: "",
      date_of_birth: "",
      religion: "",
      qualification: "",
      address: "",
      marital_status: "",
      mobile_number: "",
      profile_pic: "",
      status: "",
      email: "",
      password: "",
    });
  };

  useEffect(() => {
    if (editingTeacher) {
      const formattedDOB = editingTeacher.date_of_birth
        ? new Date(editingTeacher.date_of_birth).toISOString().split("T")[0]
        : "";

      const formattedJD = editingTeacher.createdAt
        ? new Date(editingTeacher.createdAt).toISOString().split("T")[0]
        : "";
      setFormData({
        userid: editingTeacher.userid,
        username: editingTeacher.username,
        admission_date: formattedJD,
        gender: editingTeacher.gender,
        date_of_birth: formattedDOB,
        religion: editingTeacher.religion,
        qualification: editingTeacher.qualification,
        address: editingTeacher.address,
        marital_status: editingTeacher.marital_status,
        mobile_number: editingTeacher.mobile_number,
        profile_pic: editingTeacher.profile_pic,
        status: editingTeacher.status,
        email: editingTeacher.email,
        password: "",
      });
    } else {
      resetFormData();
    }
  }, [editingTeacher]);

  return (
    <>
      {show && <div className="modal-backdrop blur-background"></div>}
      <div
        className={`modal ${show ? "show d-block" : ""}`}
        role="dialog"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {userInfo && userInfo.role === "teacher"
                  ? `Update Profile`
                  : `Add New Teacher`}
              </h5>
              <button type="button" className="close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body"
              style={{
                maxHeight: "70vh",

                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <form onSubmit={handleSubmit}>
                <div className="box-body">
                  {userInfo && userInfo.role === "teacher" ? (
                    <div className="row">
                      <div className="form-group no-margin col-md-12">
                        <label>
                          Email address <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Enter email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="form-group no-margin col-md-12">
                        <label>
                          Password <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="form-control"
                          placeholder={
                            editingTeacher ? "******" : "Enter password"
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Full Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          value={formData.username}
                          onChange={handleChange}
                          required
                          placeholder="Name"
                        />
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Date of Joining{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="admission_date"
                          value={formData.admission_date}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Gender <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Gender</option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                          <option value="OTHER">Other</option>
                        </select>
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Date of Birth <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="date_of_birth"
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Religion <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="religion"
                          value={formData.religion}
                          onChange={handleChange}
                          required
                          placeholder="Religion"
                        />
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Qualification <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          name="qualification"
                          value={formData.qualification}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Qualification</option>
                          <option value="SSCE">SSCE</option>
                          <option value="ND">ND</option>
                          <option value="NCE">NCE</option>
                          <option value="HND">HND</option>
                          <option value="Degree">Degree</option>
                          <option value="Masters">Masters</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Address <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          className="form-control"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          required
                          placeholder="Current Address"
                        ></textarea>
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Marital Status <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="marital_status"
                          value={formData.marital_status}
                          onChange={handleChange}
                          required
                          placeholder="Marital Status"
                        />
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="mobile_number"
                          value={formData.mobile_number}
                          onChange={handleChange}
                          required
                          placeholder="Mobile Number"
                        />
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label htmlFor="profile_pic">Profile Picture</label>
                        <input
                          type="file"
                          className="form-control"
                          name="profile_pic"
                          onChange={handleFileChange}
                        />
                      </div>

                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Status <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          className="form-control"
                          name="status"
                          value={formData.status}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Status</option>
                          <option value="ACTIVE">Active</option>
                          <option value="INACTIVE">Inactive</option>
                        </select>
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group no-margin col-md-6">
                        <label className="mb-0">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          placeholder={
                            editingTeacher ? "******" : "Enter password"
                          }
                        />
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="card-footer" style={{ paddingTop: "20px" }}>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeacherModal;
