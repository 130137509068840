import React from "react";
import { NavLink } from "react-router-dom";

function StudentSideBarItems({ style, changeStyle }) {
  return (
    <ul className={style} id="accordionSidebar">
      {/*  <!-- Sidebar - Brand --> */}
      <div className="d-flex align-items-center justify-content-center align-items-center">
        <NavLink
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="#"
        >
          <img
            className="h-100 w-100 rounded-circle"
            src="img/undraw_profile.svg"
            alt=""
          />

          <div className="sidebar-brand-text mx-3">Student</div>
        </NavLink>
        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={changeStyle}
          ></button>
        </div>
      </div>

      {/*   <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/*  <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        <NavLink className="nav-link" to="/dashboard">
          <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseProfile"
          aria-expanded="true"
          aria-controls="collapseProfile"
        >
          <i className="fas fa-user text-white"></i>
          <span>Profile</span>
        </NavLink>
      </li>
    </ul>
  );
}

export default StudentSideBarItems;
