import React from "react";

const PrintModal = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null; // Ensure modal only renders when open

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
        <button className="print-btn" onClick={handlePrint}>
          Print
        </button>

        <div className="printable-content">{content}</div>
      </div>
    </div>
  );
};

export default PrintModal;
