import React, { useState } from "react";
import toast from "react-hot-toast";
import { useAddParentMutation } from "../hooks/parent";
import { convertToBase64 } from "../utils";

const AddParentModal = ({ show, handleClose }) => {
  const { mutateAsync: addparent } = useAddParentMutation();

  const [formData, setFormData] = useState({
    full_name: "",
    gender: "",
    religion: "",
    occupation: "",
    mobile_number: "",
    profile_picture: "",
    status: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const base64 = await convertToBase64(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: base64,
      }));
    }
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      console.log(formData);
      addparent(formData);

      handleClose();
      toast.success("Added successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <div
      className={`modal ${show ? "show d-block" : ""}`}
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", overflowY: "auto" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Parent</h5>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="row">
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Full Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="full_name"
                      className="form-control"
                      placeholder="Enter Full Name"
                      value={formData.full_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Occupation
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="occupation"
                      className="form-control"
                      placeholder="Enter Occupation"
                      value={formData.occupation}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group no-margin col-md-6">
                    <label>
                      Gender<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={formData.gender}
                      onChange={handleChange}
                      name="gender"
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </select>
                  </div>

                  <div className="form-group no-margin col-md-6">
                    <label>
                      Mobile Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="mobile_number"
                      value={formData.mobile_number}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group no-margin col-md-6">
                    <label>
                      Religion <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="religion"
                      placeholder="Religion"
                      value={formData.religion}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group no-margin col-md-6">
                    <label>
                      Profile <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="profile_pic"
                      onChange={handleFileChange}
                    />
                  </div>

                  <div className="form-group no-margin col-md-6">
                    <label>Status</label>
                    <select
                      className="form-control"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <option value="">Select Status</option>
                      <option value="Active">Active</option>
                      <option value="InActive">Inactive</option>
                    </select>
                  </div>

                  <div className="border-2 bg-secondary" />

                  <div className="form-group no-margin col-md-12">
                    <label>
                      Email address <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Enter email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="form-group no-margin col-md-12">
                    <label>
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Password"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParentModal;
