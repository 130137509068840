import React, { useContext, useEffect, useState } from "react";
import SideBar from "../Components/SideBar";
import {
  useFetchSchoolSettings,
  useGetTermsQuery,
  usePsychomotorByStudentAndClassQuery,
} from "../hooks/admin";
import {
  useGetResultsByStudentQuery,
  useGetStudentQuery,
} from "../hooks/result";
import { Store } from "../Store";
import { useStudentsQuery } from "../hooks/studentHook";
import PrintModal from "../Components/PrintModal";

const StudentResult = () => {
  const { data: terms } = useGetTermsQuery();
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [selectedTerm, setSelectedTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const [student_id, setStudent_id] = useState("");
  const [studentId, setStudentId] = useState("");
  const { data: studentResults } = useGetResultsByStudentQuery(student_id);

  console.log(studentResults);

  const { data: student } = useGetStudentQuery({
    studentId,
    enabled: studentId,
  });

  const { data: psycho } = usePsychomotorByStudentAndClassQuery(
    student_id,
    selectedClass
  );

  const partA = psycho ? JSON.parse(psycho.part_a) : {};
  const partB = psycho ? JSON.parse(psycho.part_b) : {};

  // Extract remarks with fallback
  const classTeacherRemark =
    psycho?.class_teacher_remark || "No remark available";
  const headTeacherRemark =
    psycho?.head_teacher_remark || "No remark available";

  const partAFields = [
    "Aesthetic",
    "Creativity",
    "Honesty",
    "Responsibility",
    "Initiative",
    "Perseverance",
    "Leadership",
    "Obedience",
    "Politeness",
    "Self Control",
  ];

  const partBFields = [
    "Communication",
    "Handwriting",
    "Musical Skills",
    "Handling of Tools",
    "Painting/Drawing",
    "Sports/Game",
  ];

  const { data: settings } = useFetchSchoolSettings();

  const { data: students } = useStudentsQuery();

  // Get the length of students in the same class
  let sameClassCount = 0;
  if (students && student) {
    sameClassCount = students.filter(
      (stud) => stud.class === student[0].class
    ).length;
  }

  // Calculate Grand Total and Maximum Possible Score
  const totalScore = filteredResults?.reduce(
    (acc, res) => acc + res.calculated_total,
    0
  );
  const maxPossibleScore = filteredResults?.length * 100;

  // Calculate Percentage
  const percentage = ((totalScore / maxPossibleScore) * 100).toFixed(1);

  // Function to determine grade and description based on total score
  const determineGrade = (total) => {
    if (total >= 75 && total <= 100) {
      return { grade: "A1", description: "Excellent" };
    } else if (total >= 70 && total <= 74) {
      return { grade: "A", description: "Very Good" };
    } else if (total >= 65 && total <= 69) {
      return { grade: "B3", description: "Good" };
    } else if (total >= 60 && total <= 64) {
      return { grade: "C4", description: "Credit" };
    } else if (total >= 55 && total <= 59) {
      return { grade: "C5", description: "Credit" };
    } else if (total >= 50 && total <= 54) {
      return { grade: "C6", description: "Credit" };
    } else if (total >= 45 && total <= 49) {
      return { grade: "D7", description: "Pass" };
    } else if (total >= 40 && total <= 44) {
      return { grade: "E8", description: "Pass" };
    } else if (total >= 0 && total <= 39) {
      return { grade: "F9", description: "Fail" };
    } else {
      return { grade: "N/A", description: "Invalid Score" };
    }
  };

  // Determine Exam Grade based on Percentage
  const determine = (percentage) => {
    if (percentage >= 75) return "A1";
    if (percentage >= 70) return "B2";
    if (percentage >= 65) return "B3";
    if (percentage >= 60) return "C4";
    if (percentage >= 55) return "C5";
    if (percentage >= 50) return "C6";
    if (percentage >= 45) return "D7";
    if (percentage >= 40) return "E8";
    return "F9";
  };

  const examGrade = determine(percentage);

  useEffect(() => {
    if (userInfo) {
      setStudent_id(userInfo.user.userid);
      setStudentId(userInfo.user.userid);
    }
  }, [userInfo]);

  useEffect(() => {
    console.log("Student Results:", studentResults);
  }, [studentResults]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    console.log("selected", e.target);
    if (name === "exam_id") {
      setSelectedTerm(value);
    } else if (name === "class_id") {
      setSelectedClass(value);
    }
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterApplied(true);
    setModalOpen(true);

    console.log("ST: ", selectedTerm);

    if (studentResults) {
      const filtered = studentResults.filter(
        (result) =>
          String(result.selected_term) === String(selectedTerm) &&
          result.selected_class === selectedClass
      );
      setFilteredResults(filtered);
    }
  };

  const handleReset = () => {
    setSelectedTerm("");
    setSelectedClass("");
    setFilterApplied(false);
    setFilteredResults([]);
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <h3>Student Result</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="box box-primary">
              <form onSubmit={handleFilterSubmit}>
                <div className="box-body">
                  <div className="row">
                    <div className="form-group col-md-4 pb-3">
                      <select
                        className="form-control"
                        name="exam_id"
                        value={selectedTerm}
                        onChange={handleFilterChange}
                        required
                      >
                        <option value="">Select Term</option>
                        {terms?.map((term) => (
                          <option key={term.id} value={term.id}>
                            {term.term_name === "1"
                              ? "FIRST TERM"
                              : term.term_name === "2"
                              ? "SECOND TERM"
                              : term.term_name === "3"
                              ? "THIRD TERM"
                              : "UNKNOWN TERM"}{" "}
                            {term.date}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-4 pb-3">
                      <select
                        className="form-control"
                        name="class_id"
                        value={selectedClass}
                        onChange={handleFilterChange}
                        required
                      >
                        <option value="">Select Class</option>
                        {[
                          ...new Set(
                            studentResults?.map((cls) => cls.selected_class)
                          ),
                        ].map((uniqueClass, index) => (
                          <option key={index} value={uniqueClass}>
                            {uniqueClass}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-4 pt-1">
                      <button className="btn btn-primary" type="submit">
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {filterApplied && filteredResults.length > 0 ? (
              <PrintModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                content={
                  <div>
                    <div className="box mt-4">
                      <table className="table  w-100 ">
                        <tbody>
                          <tr>
                            <td width="5%">
                              <img
                                src="/img/logo.jpg"
                                alt="School Logo"
                                style={{ width: "88px", objectFit: "contain" }}
                              />
                            </td>

                            <td width="85%" style={{ textAlign: "center" }}>
                              <h1
                                style={{
                                  fontSize: "20px",
                                  fontWeight: 700,
                                  color: "#344767",
                                }}
                              >
                                {settings?.school_name}
                              </h1>
                              <p
                                style={{
                                  color: "#344767",
                                  margin: "5px 0",
                                }}
                              >
                                <div
                                  style={{ fontSize: "20px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: settings?.school_address,
                                  }}
                                />
                              </p>
                              <p
                                style={{
                                  color: "#344767",
                                  margin: "5px 0",
                                  fontSize: "14px",
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: settings?.school_address_second,
                                  }}
                                />
                              </p>
                            </td>

                            <td width="10%">
                              <img
                                src="/img/person.jfif"
                                alt="Student"
                                style={{
                                  width: "70px",
                                  height: "80px",
                                  margin: "10px",
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div
                        style={{
                          padding: "10px",
                          marginBottom: "20px",
                          borderBottom: "1px solid gray",
                          borderTop: "1px solid gray",
                        }}
                      >
                        <table className="table  w-100">
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    margin: "5px 0",
                                    alignItems: "start",
                                  }}
                                >
                                  <b style={{ color: "#344767" }}>
                                    STUDENT'S NAME:
                                  </b>{" "}
                                  <span>{student && student[0]?.username}</span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>GENDER:</b>{" "}
                                  <span>{student && student[0]?.gender} </span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>RELIGION:</b>{" "}
                                  <span>{student && student[0]?.religion}</span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>
                                    DATE OF BIRTH:
                                  </b>{" "}
                                  <span>
                                    {student &&
                                      new Date(
                                        student[0].date_of_birth
                                      ).toLocaleDateString("en-US", {
                                        year: "2-digit",
                                        month: "numeric",
                                        day: "numeric",
                                      })}
                                  </span>
                                </p>
                              </td>

                              <td style={{ textAlign: "start" }}>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>CLASS:</b>{" "}
                                  <span>{student && student[0]?.class}</span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>
                                    NO. IN CLASS:
                                  </b>{" "}
                                  <span>{sameClassCount}</span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>
                                    ADMISSION NO.:
                                  </b>{" "}
                                  <span className="pull-right">
                                    SF/
                                    {student &&
                                    student.length > 0 &&
                                    student[0]?.createdAt
                                      ? new Date(
                                          student[0].createdAt
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                        })
                                      : "N/A"}
                                    /
                                    {student && student.length > 0
                                      ? student[0]?.userid
                                      : "0"}
                                  </span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>
                                    RESUMPTION:
                                  </b>{" "}
                                  <span>
                                    {settings &&
                                      new Date(
                                        settings?.resumption
                                      ).toLocaleDateString("en-US", {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      })}
                                  </span>
                                </p>
                              </td>

                              <td style={{ textAlign: "start" }}>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>
                                    TOTAL SCORE:
                                  </b>
                                  <span>
                                    {totalScore} / {maxPossibleScore}
                                  </span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>
                                    PERCENTAGE:
                                  </b>{" "}
                                  <span>{percentage}%</span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>
                                    EXAM GRADE:
                                  </b>{" "}
                                  <span>{examGrade}</span>
                                </p>
                                <p
                                  style={{ fontSize: "14px", margin: "5px 0" }}
                                >
                                  <b style={{ color: "#344767" }}>TERM:</b>{" "}
                                  <span>
                                    {terms && terms.length > 0
                                      ? terms[0]?.term_name === "1"
                                        ? "FIRST TERM"
                                        : terms[0]?.term_name === "2"
                                        ? "SECOND TERM"
                                        : terms[0]?.term_name === "3"
                                        ? "THIRD TERM"
                                        : "UNKNOWN TERM"
                                      : "Term data not available"}
                                    {terms && terms[0]?.date}
                                  </span>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="box-header with-border">
                        <h3 className="box-title">Results</h3>
                      </div>
                      <div className="box-body">
                        {filterApplied ? (
                          filteredResults.length > 0 ? (
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Subject</th>
                                  <th>CA1</th>
                                  <th>CA2</th>
                                  <th>CA3</th>
                                  <th>CA4</th>
                                  <th>EXAM</th>
                                  <th>TOTAL</th>
                                  <th>GRADE</th>
                                  <th
                                    style={{
                                      width: "95px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Remark
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredResults.map((result) => {
                                  const { grade, description } = determineGrade(
                                    result.calculated_total
                                  );
                                  return (
                                    <tr key={result.id}>
                                      <td style={{ textAlign: "start" }}>
                                        {result.subject}
                                      </td>
                                      <td>{result.assignment}</td>
                                      <td>{result.first_ca}</td>
                                      <td>{result.second_ca}</td>
                                      <td>{result.project}</td>
                                      <td>{result.exam}</td>
                                      <td>{result.calculated_total}</td>
                                      <td>{grade}</td>
                                      <td>{description}</td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td colSpan="2" className="ps-3 text-center">
                                    <b
                                      className="mb-0 "
                                      style={{ color: "#344767" }}
                                    >
                                      GRAND TOTAL: {totalScore} /{" "}
                                      {maxPossibleScore}
                                    </b>
                                  </td>
                                  <td colSpan="5" className="ps-3 text-center">
                                    <b
                                      className="mb-0 "
                                      style={{ color: "#344767" }}
                                    >
                                      PERCENTAGE: {percentage}%
                                    </b>
                                  </td>
                                  <td colSpan="3" className="ps-3 text-center">
                                    <b
                                      className="mb-0 "
                                      style={{ color: "#344767" }}
                                    >
                                      EXAM GRADE: {examGrade}
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ) : (
                            <p>No results found for the selected filters.</p>
                          )
                        ) : (
                          <p>Select a term and class to view results.</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        marginTop: -10,
                      }}
                    >
                      <table className="mt table table-bordered w-100">
                        <thead>
                          <tr style={{ backgroundColor: "#e91e63" }}>
                            <th className="text-center text-black py-1">
                              PSYCHOMOTOR
                            </th>
                            {partBFields.map((field, index) => (
                              <th
                                key={index}
                                className="text-center text-black"
                              >
                                {field}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="text-xxs ps-3 text-center">
                          <tr>
                            <td
                              style={{
                                border: "1px solid lightgray",
                                margin: "0px",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              RATING
                            </td>
                            {partBFields.map((field, index) => (
                              <td
                                key={index}
                                style={{
                                  border: "1px solid lightgray",
                                  textAlign: "center",
                                  color: "#344767",
                                }}
                              >
                                {partB[field] || "N/A"}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>

                      <table
                        className="table table-bordered w-100"
                        style={{ marginTop: "-15px" }}
                      >
                        <thead>
                          <tr style={{ backgroundColor: "#e91e63" }}>
                            <th className="text-center text-black py-1">
                              BEHAVIORS
                            </th>
                            {partAFields.map((field, index) => (
                              <th
                                key={index}
                                className="text-center text-black"
                              >
                                {field}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="text-xxs ps-3 text-center">
                          <tr>
                            <td
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              RATING
                            </td>
                            {partAFields.map((field, index) => (
                              <td
                                key={index}
                                style={{
                                  border: "1px solid lightgray",
                                  textAlign: "center",
                                  color: "#344767",
                                }}
                              >
                                {partA[field] || "N/A"}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>

                      <table
                        className="table table-bordered w-100"
                        style={{ marginTop: "-16px" }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              GRADING SCALE
                            </th>
                            <th
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              70 - 100
                            </th>
                            <th
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              60 - 69
                            </th>
                            <th
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              50 - 59
                            </th>
                            <th
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              40 - 49
                            </th>
                            <th
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              0 - 39
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-xxs ps-3 text-center">
                          <tr>
                            <td
                              style={{
                                border: "1px solid lightgray",
                                textAlign: "center",
                                color: "#344767",
                              }}
                            >
                              GRADE
                            </td>
                            <td
                              style={{
                                border: "1px solid lightgray",
                                color: "#344767",
                              }}
                            >
                              A
                            </td>
                            <td
                              style={{
                                border: "1px solid lightgray",
                                color: "#344767",
                              }}
                            >
                              B
                            </td>
                            <td
                              style={{
                                border: "1px solid lightgray",
                                color: "#344767",
                              }}
                            >
                              C
                            </td>
                            <td
                              style={{
                                border: "1px solid lightgray",
                                color: "#344767",
                              }}
                            >
                              D
                            </td>
                            <td
                              style={{
                                border: "1px solid lightgray",
                                color: "#344767",
                              }}
                            >
                              F9
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* REMARK */}
                      <div className="col-md-12" style={{ marginTop: "-16px" }}>
                        <div className="row">
                          <table className="table table-bordered table-scrow w-100">
                            <thead>
                              <tr style={{ backgroundColor: "#e91e63" }}>
                                <th className="text-center text-black">
                                  <b>CLASS TEACHER'S REMARK</b>
                                </th>
                                <th className="text-center text-black">
                                  <b>HEAD TEACHER/PRINCIPAL'S COMMENT</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid lightgray",
                                    textAlign: "center",
                                    color: "#344767",
                                    padding: "0 5px",
                                  }}
                                >
                                  <span className="text-uppercase">
                                    {classTeacherRemark}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    border: "1px solid lightgray",
                                    textAlign: "center",
                                    width: "50%",
                                    color: "#344767",
                                  }}
                                >
                                  <span className="text-uppercase">
                                    {headTeacherRemark}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        className="col-md-12 "
                        style={{ marginTop: "-20px" }}
                      >
                        <div className="row">
                          <table className="table table-bordered table-scrow w-100">
                            <thead>
                              <tr
                                style={{
                                  backgroundColor: "#e91e63",
                                }}
                              >
                                <th className=" text-black text-center">
                                  <b>STAMP/SIGNATURE</b>
                                </th>
                                <th className="text-black text-center">
                                  <b>NOTICE</b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    border: "1px solid lightgray",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    style={{ height: "100px", width: "100px" }}
                                    src={settings?.stamp}
                                    alt="Stamp/Signature"
                                  />
                                </td>
                                <td
                                  style={{
                                    border: "1px solid lightgray",
                                    textAlign: "center",
                                    color: "#344767",
                                  }}
                                >
                                  <ol style={{ textAlign: "left" }}>
                                    <li style={{ fontSize: "15px" }}>
                                      Students are expected to print out the
                                      original report card and keep it neat and
                                      clean.
                                    </li>
                                    <li style={{ fontSize: "15px" }}>
                                      In case this report card is misplaced, a
                                      duplicate copy can only be issued after
                                      payment of extra fee.
                                    </li>
                                    <li style={{ fontSize: "15px" }}>
                                      For any complaint at any time kindly
                                      contact the school personally.
                                    </li>
                                  </ol>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              />
            ) : (
              filterApplied && <p className="text-danger">No results found.</p>
            )}
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default StudentResult;
