import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTermMutation } from "../hooks/admin";

const AddTermModal = ({ show, handleClose, editingTerm }) => {
  const { mutate: addTerm } = useTermMutation();

  const [formData, setFormData] = useState({
    term_name: "",
    date: "",
    status: "",
  });

  useEffect(() => {
    // If editingTerm is provided, populate the form with its data
    if (editingTerm) {
      setFormData({
        term_name: editingTerm.term_name,
        date: editingTerm.date,
        status: editingTerm.status,
      });
    } else {
      // Reset form data for adding a new term
      setFormData({
        term_name: "",
        date: "",
        status: "",
      });
    }
  }, [editingTerm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      addTerm({ ...formData, id: editingTerm ? editingTerm.id : undefined }); // Send formData to the mutation
      handleClose(); // Close the modal
      toast.success(
        editingTerm ? "Term updated successfully" : "Term added successfully"
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div
      className={`modal ${show ? "show d-block" : ""}`}
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {editingTerm ? "Edit Term" : "Add Term"}
            </h5>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="row">
                  <div className="form-group no-margin col-md-12">
                    <label>
                      Term <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      name="term_name"
                      onChange={handleChange}
                      value={formData.term_name}
                      className="form-control"
                      required
                    >
                      <option value="">Select Term</option>
                      <option value="1">FIRST TERM</option>
                      <option value="2">SECOND TERM</option>
                      <option value="3">THIRD TERM</option>
                    </select>
                  </div>
                  <div className="form-group no-margin col-md-12">
                    <label>
                      DATE/Year <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      placeholder="Enter date or year"
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-12">
                    <label>Status</label>
                    <select
                      className="form-control"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <option value="">Select Status</option>
                      <option value="ACTIVE">Active</option>
                      <option value="INACTIVE">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTermModal;
