/** @format */

import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "../apiClient";

export const useSigninMutation = () => {
  return useMutation({
    mutationFn: async ({ email, password }) =>
      (
        await apiClient.post(`api/users/login`, {
          email,
          password,
        })
      ).data,
  });
};
export const useLogoutMutation = () => {
  return useMutation({
    mutationFn: async (token) =>
      (
        await apiClient.post(`api/users/logout`, {
          token,
        })
      ).data,
  });
};
export const useStudentSigninMutation = () => {
  return useMutation({
    mutationFn: async ({ userid, username }) =>
      (
        await apiClient.post(`api/auths/auth`, {
          userid,
          username,
        })
      ).data,
  });
};
export const useActiveUsersQuery = () => {
  return useQuery({
    queryKey: ["activeUsers"],
    queryFn: async () => (await apiClient.get(`api/users/active-users`)).data,
  });
};
