import SideBar from "../Components/SideBar";

import { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
// import { useClassesQuery } from "../hooks/classHook";
import toast from "react-hot-toast";
import { useClassesQuery } from "../hooks/classHook";

const ITEMS_PER_PAGE = 10;

function Classes() {
  const [currentPage, setCurrentPage] = useState(0);

  const { data: classes, isPending, isError, error } = useClassesQuery();

  if (isPending) {
    return <span className="spinner" />;
  }

  if (isError) {
    return toast.error(error.message);
  }

  if (!classes || classes.length === 0) {
    return <div>No classes found.</div>;
  }

  // Calculate the data to display on the current page
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = classes.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div class="card w-100 px-2 mb-3" style={boxStyle}>
          <div class="box-header with-border">
            <h3 class="box-title">Search Class</h3>
          </div>
          <form action="" method="get">
            <div class="box-body">
              <div class="row">
                <div class="form-group no-margin col-md-6">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search Class"
                  />
                </div>
                <div
                  class="form-group no-margin col-md-6"
                  style={{ paddingTop: "33px" }}
                >
                  <button type="submit" class="btn btn-primary">
                    Search
                  </button>
                  <button type="submit" class="btn ml-2 btn-success">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/*  <!-- Content Row --> */}
        <div className="row" style={{ overflowX: "auto" }}>
          {isPending ? <span className="spinner" /> : ""}
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th className="text-center">SN</th>
                <th>Name</th>
                <th className="text-center">1st Term Fees</th>
                <th className="text-center">2nd Term Fees</th>
                <th className="text-center">3rd Term Fees</th>
                <th className="text-center">Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.SN}>
                  <td className="text-center">{item.SN}</td>
                  <td>{item.Name}</td>
                  <td className="text-center">{item.firstTermFees}</td>
                  <td className="text-center">{item.secondTermFees}</td>
                  <td className="text-center">{item.thirdTermFees}</td>
                  <td className="text-center">
                    <span
                      className={`badge ${
                        item.Status === "Active"
                          ? "bg-success text-white"
                          : "bg-secondary text-white"
                      }`}
                    >
                      {item.Status}
                    </span>
                  </td>
                  <td className="d-flex justify-content-end">
                    <button className="btn btn-success">edit</button>
                    <button className="btn btn-danger ml-2">delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={
            <div className="pagination-label">
              <FaArrowLeft />
            </div>
          }
          nextLabel={
            <div className="pagination-label">
              <FaArrowRight />
            </div>
          }
          breakLabel="..."
          pageCount={Math.ceil(classes.length / ITEMS_PER_PAGE)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName="pagination-container pagination justify-content-end mt-3"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          activeClassName="active"
        />

        {/*  <!-- Content Row --> */}
      </div>
    </SideBar>
  );
}

export default Classes;
