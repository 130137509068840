/* eslint-disable jsx-a11y/alt-text */
import SideBar from "../Components/SideBar";

import { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";

import toast from "react-hot-toast";
import AddParentModal from "../Components/AddParentModal";
import { useParentQuery } from "../hooks/parent";

const ITEMS_PER_PAGE = 10;

function Parents() {
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const { data: parents, isPending, isError, error } = useParentQuery();

  if (isPending) {
    return <span className="spinner"></span>;
  }

  if (isError) {
    return toast.error(error.message);
  }

  // if (!parents || parents.length === 0) {
  //   return <div>No parent found.</div>;
  // }

  // Calculate the data to display on the current page
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = parents.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <SideBar>
      <div className="container-fluid">
        <div class="card w-100 py-2 px-2 mb-3" style={boxStyle}>
          <div class="box-header with-border">
            <h3 class="box-title text-black">Search Parent</h3>
          </div>
          <form action="" method="get">
            <div class="box-body">
              <div class="row">
                <div class="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search name"
                  />
                </div>
                <div class="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search email"
                  />
                </div>
                <div
                  class="form-group no-margin col-md-4"
                  style={{ paddingTop: "33px" }}
                >
                  <button type="submit" class="btn btn-primary">
                    Search
                  </button>
                  <button type="submit" class="btn ml-2 btn-success">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/*  <!-- Content Row --> */}
          <div className="row" style={{ overflowX: "auto" }}>
            <div className="col-md-12">
              <div className="px-4 row">
                <div className="col-md-6">
                  <p className="mb-0">Parent List</p>
                </div>
                <div className="col-md-6 text-right">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={handleShowModal}
                  >
                    Add Parent
                  </button>
                </div>
              </div>
            </div>
            {/* AddTeacherModal Component */}
            <AddParentModal show={showModal} handleClose={handleCloseModal} />
            {isPending ? <span className="spinner"></span> : ""}
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">USERID</th>
                  <th className="text-center">PHOTO</th>
                  <th>USER NAME</th>
                  <th>STATUS</th>
                  <th className="text-center">LAST SEEN</th>
                  <th className="text-center">Action</th>
                </tr>
                {parents.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan="6">
                      No parent found
                    </td>
                  </tr>
                )}
              </thead>
              <tbody>
                {currentItems.map((item) => (
                  <tr key={item.SN}>
                    <td className="text-center">{item.id}</td>

                    <td className="  text-center">
                      {item.profile_pic ? (
                        <img
                          className="rounded-circle"
                          style={{ width: "50px", height: "50px" }}
                          src={item.profile_pic}
                        />
                      ) : (
                        <img
                          className="rounded-circle"
                          style={{ width: "50px", height: "50px" }}
                          src="img/undraw_profile.svg"
                        />
                      )}
                    </td>
                    <td>
                      <p>{item.full_name}</p>
                      <span>{item.email}</span>
                    </td>
                    <td>
                      <span
                        className={`badge ${
                          item.status === "Active" || "ACTIVE"
                            ? "bg-success text-white"
                            : "bg-secondary text-white"
                        }`}
                      >
                        {item.status}
                      </span>
                    </td>
                    <td className="text-center">{item.last_seen}</td>
                    <td className="text-center">
                      <button className="btn btn-success btn-sm text-black">
                        EDIT
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={
              <div className="pagination-label">
                <FaArrowLeft />
              </div>
            }
            nextLabel={
              <div className="pagination-label">
                <FaArrowRight />
              </div>
            }
            breakLabel="..."
            pageCount={Math.ceil(parents.length / ITEMS_PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container pagination justify-content-end mt-3"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>

        {/*  <!-- Content Row --> */}
      </div>
    </SideBar>
  );
}

export default Parents;
