/* eslint-disable jsx-a11y/alt-text */
import SideBar from "../Components/SideBar";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ReactPaginate from "react-paginate";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useState } from "react";

import { CgSpinner } from "react-icons/cg";

import toast from "react-hot-toast";
import {
  useDeleteTeacherMutation,
  useTeachersQuery,
} from "../hooks/teacherHook";
import AddTeacherModal from "../Components/AddTeacherModal";
import { NavLink } from "react-router-dom";
import { useActiveUsersQuery } from "../services/auth";

const ITEMS_PER_PAGE = 10;

function Teachers() {
  const [currentPage, setCurrentPage] = useState(0);
  const [editingTeacher, setEditingTeacher] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const { data: teachers, isPending, isError, error } = useTeachersQuery();
  const { data } = useActiveUsersQuery();
  console.log("active user", data);
  const { mutate: deleteTeacher, isPending: isDeleting } =
    useDeleteTeacherMutation();

  const handleShowModal = (term = null) => {
    setEditingTeacher(term); // Set the editing term if provided
    setShowModal(true); // Show the modal
  };

  if (isPending) {
    return <span className="spinner"></span>;
  }

  if (isError) {
    return toast.error(error.message);
  }

  if (!teachers || teachers.length === 0) {
    return <div>No teachers found.</div>;
  }

  // Calculate the data to display on the current page
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = teachers.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleDeleteTeacher = (userid) => {
    if (window.confirm("Are you sure you want to delete this teacher?")) {
      deleteTeacher(userid, {
        onSuccess: () => {
          toast.success("Teacher deleted successfully!");
        },
        onError: (error) => {
          toast.error(`Error deleting teacher: ${error.message}`);
        },
      });
    }
  };

  // Handle editing term
  const handleEditTeacher = (term) => {
    handleShowModal(term);
  };

  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingTeacher(null);
  };

  dayjs.extend(relativeTime);

  return (
    <SideBar>
      <div className="container-fluid">
        <div class="card w-100 py-2 px-2 mb-3" style={boxStyle}>
          <div class="box-header with-border">
            <h3 class="box-title text-black">Search Teacher</h3>
          </div>
          <form action="" method="get">
            <div class="box-body">
              <div class="row">
                <div class="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search name"
                  />
                </div>
                <div class="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="text"
                    value=""
                    name="name"
                    class="form-control"
                    placeholder="Search email"
                  />
                </div>
                <div
                  class="form-group no-margin col-md-4"
                  style={{ paddingTop: "33px" }}
                >
                  <button type="submit" class="btn btn-primary">
                    Search
                  </button>
                  <button type="submit" class="btn ml-2 btn-success">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/*  <!-- Content Row --> */}
          <div className="row" style={{ overflowX: "auto" }}>
            <div className="col-md-12">
              <div className="px-4 d-flex justify-content-between align-items-center">
                <p className="mb-0">Teachers List</p>
                <button
                  className="btn btn-success btn-sm"
                  onClick={handleShowModal}
                >
                  Add Teacher
                </button>
              </div>
            </div>

            {/* AddTeacherModal Component */}
            <AddTeacherModal
              show={showModal}
              handleClose={handleCloseModal}
              editingTeacher={editingTeacher}
            />

            {isPending ? <span className="spinner"></span> : ""}
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">USERID</th>
                  <th className="text-center">PHOTO</th>
                  <th>USER</th>
                  <th className="text-center">LAST SEEN</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => {
                  const userRecord = data?.activeUsers?.find(
                    (user) =>
                      user.email.toLowerCase() === item.email.toLowerCase()
                  );
                  console.log("user Records", userRecord);
                  const isOnline = userRecord?.token ? true : false;

                  return (
                    <tr key={item.SN}>
                      <td className="text-center">{item.userid}</td>
                      <td className="text-center">
                        <img
                          className="rounded-circle"
                          style={{ width: "50px", height: "50px" }}
                          src="img/undraw_profile.svg"
                        />
                      </td>

                      <td>
                        <p>{item.username}</p>
                        <span
                          className={`badge ${
                            item.status === "ACTIVE"
                              ? "bg-success text-white"
                              : "bg-secondary text-white"
                          }`}
                        >
                          {item.status}
                        </span>
                      </td>
                      <td className="text-center">
                        {isOnline ? (
                          <span className="badge bg-info text-white">
                            Online
                          </span>
                        ) : (
                          <span>
                            {userRecord?.updated_at
                              ? dayjs(userRecord.updated_at).fromNow()
                              : "Not Identified"}
                          </span>
                        )}
                      </td>

                      <td className="d-flex justify-content-end">
                        <button
                          className="btn btn-success text-black"
                          onClick={() => handleEditTeacher(item)}
                        >
                          edit
                        </button>
                        <button
                          className="btn btn-danger ml-2 btn-sm text-black"
                          onClick={() => handleDeleteTeacher(item.userid)}
                          disabled={isDeleting}
                        >
                          {isDeleting ? (
                            <CgSpinner className="animate-spin" size={20} />
                          ) : (
                            "Delete"
                          )}
                        </button>

                        <NavLink to={`/teacherprofile/${item.userid}`}>
                          <button className="btn text-black btn-warning ml-2">
                            view profile
                          </button>
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={
              <div className="pagination-label">
                <FaArrowLeft />
              </div>
            }
            nextLabel={
              <div className="pagination-label">
                <FaArrowRight />
              </div>
            }
            breakLabel="..."
            pageCount={Math.ceil(teachers.length / ITEMS_PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container pagination justify-content-end mt-3"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />

          {/*  <!-- Content Row --> */}
        </div>
      </div>
    </SideBar>
  );
}

export default Teachers;
