import { useState } from "react";
import SideBar from "../Components/SideBar";
import ReactPaginate from "react-paginate";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";
import toast from "react-hot-toast";
import { useStudentsQuery } from "../hooks/studentHook";

const ITEMS_PER_PAGE = 50;

function ScratchCards() {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const { data: students, isPending, isError, error } = useStudentsQuery();

  if (isPending) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <CgSpinner size={50} className="text-primary" />
      </div>
    );
  }

  if (isError) {
    toast.error(error.message);
    return <div className="alert alert-danger">Error loading data.</div>;
  }

  if (!students || students.length === 0) {
    return <div className="alert alert-warning">No students found.</div>;
  }

  const activeStudents = students.filter(
    (student) => student.status.toLowerCase() === "active"
  );

  // Further filtering based on search term
  const filteredStudents = activeStudents.filter((student) =>
    student.class.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination Logic
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = filteredStudents.slice(startOffset, endOffset);
  const pageCount = Math.ceil(filteredStudents.length / ITEMS_PER_PAGE);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handlePrint = () => {
    const printContent = document.getElementById("printable-content").innerHTML;
    const printWindow = window.open("", "", "width=800,height=600");

    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            /* Print-friendly styles */
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
              background-color: #fff;
            }
            .card-container {
              display: flex;
              flex-wrap: wrap;
              gap: 20px;
              justify-content: start;
             
            }
            .card {
              width: 100%;
              border: 1px solid gray;
              padding: 15px;
              border-radius: 8px;
              box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
              background-color: #f9f9f9;
            }
            .text-primary {
              color: #007bff !important;
            }
            .fw-bold {
              font-weight: bold;
            }
          </style>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css">
        </head>
        <body>
          <div class="card-container">
          <div class="card">
          
          ${printContent}
          </div>
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.print();
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="card shadow-sm p-3">
          {/* Search Bar */}
          <div className="card-header bg-white border-bottom">
            <div className="d-flex gap-2">
              <h4 className="mb-3">Search Student</h4>{" "}
              <button className="print-btn" onClick={handlePrint}>
                Print
              </button>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p className="mb-0">Student List ({filteredStudents.length})</p>
                <label className="fw-bold">Enter Class</label>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control"
                  placeholder="type class"
                />
              </div>

              <div className="col-md-4 d-flex align-items-end">
                <button className="btn btn-primary me-2">Search</button>
                <button
                  className="btn btn-secondary"
                  onClick={() => setSearchTerm("")}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          {/* Student List */}
          <div id="printable-content" className="card-body">
            <div className="row">
              {currentItems.length === 0 ? (
                <div className="alert alert-info">No results found.</div>
              ) : (
                currentItems.map((item) => (
                  <div key={item.userid} className="col-md-4 mb-3">
                    <div className="p-3 bg-light border rounded shadow-sm">
                      <p className="mb-1">
                        <strong>Link:</strong>{" "}
                        <strong className="text-primary">
                          https://dashboard.sofis.com.ng/#/auth
                        </strong>
                      </p>
                      <p className="mb-1">
                        <strong>Pin:</strong> SFS-{item.userid}
                      </p>
                      <p className="mb-0">
                        <strong>Username:</strong> {item.username}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Pagination */}
          {pageCount > 1 && (
            <div className="d-flex justify-content-end mt-3">
              <ReactPaginate
                previousLabel={<FaArrowLeft />}
                nextLabel={<FaArrowRight />}
                breakLabel="..."
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                activeClassName="active"
              />
            </div>
          )}
        </div>
      </div>
    </SideBar>
  );
}

export default ScratchCards;
