import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAddOrUpdateAdminMutation } from "../hooks/admin";

const AddAdminModal = ({ show, handleClose, editingAdmin }) => {
  // const {data} = useAdminByEmailQuery()
  const { mutate: addAdmin } = useAddOrUpdateAdminMutation();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    date_of_birth: "",
    religion: "",
    gender: "",
    bio: "",
    profile_pic: "",
    status: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };
  const resetFormData = () => {
    setFormData({
      full_name: "",
      email: "",
      date_of_birth: "",
      religion: "",
      gender: "",
      bio: "",
      profile_pic: "",
      status: "",
      password: "",
    });
  };

  useEffect(() => {
    if (editingAdmin) {
      const formattedDOB = editingAdmin.date_of_birth
        ? new Date(editingAdmin.date_of_birth).toISOString().split("T")[0]
        : "";

      setFormData({
        id: editingAdmin.id,
        full_name: editingAdmin.full_name,
        gender: editingAdmin.gender,
        date_of_birth: formattedDOB,
        religion: editingAdmin.religion,
        status: editingAdmin.status,
        bio: editingAdmin.bio,
        password: "",
        email: editingAdmin.email,
      });
    }
  }, [editingAdmin]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await addAdmin(formData, editingAdmin?.email);
      handleClose();
      toast.success("Added successfully");
      resetFormData();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div
      className={`modal ${show ? "show d-block" : ""}`}
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Admin</h5>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="row">
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Full Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="full_name"
                      className="form-control"
                      placeholder="Enter Name"
                      value={formData.full_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Email address <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Enter email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      DOB <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date_of_birth"
                      value={formData.date_of_birth}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Religion <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="religion"
                      placeholder="Religion"
                      value={formData.religion}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Gender<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={formData.gender}
                      onChange={handleChange}
                      name="gender"
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </select>
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Bio <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="bio"
                      value={formData.bio}
                      onChange={handleChange}
                      placeholder="About Me"
                      required
                    ></textarea>
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Enter new or old password"
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>Status</label>
                    <select
                      className="form-control"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <option value="">Select Status</option>
                      <option value="ACTIVE">Active</option>
                      <option value="INACTIVE">Inactive</option>
                    </select>
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      File input <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="profile_pic"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdminModal;
